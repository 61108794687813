<template>
  <div class="topinfo">
    <div class="topinfo__l">
      <div class="topinfo__l_header">
        <div class="title">{{ info.rankName }}</div>
        <div class="time">发榜时间 {{ info.createTime }}</div>
      </div>
      <div class="line"></div>
      <div
        class="topinfo__l_box"
        v-for="(item, index) in list"
        :key="index"
        :class="{ top1: index == 0 }"
        @click="to('/instrumentlib/info/', item)"
      >
        <div class="topinfo__l_box-l">
          <img :src="item.apparatusFileId" alt="" />
          <div class="topIcon">
            <img v-if="index == 0" src="../assets/TOP1.png" alt="" />
            <img v-if="index == 1" src="../assets/TOP2.png" alt="" />
            <img v-if="index == 2" src="../assets/TOP3.png" alt="" />
          </div>
          <div class="topIndex" v-if="index != 0 && index != 1 && index != 2">
            {{ index + 1 }}
          </div>
        </div>
        <div class="topinfo__l_box-r">
          <div class="r_title1">{{ item.apparatusName }}</div>
          <div class="r_title2">
            <div>厂家:{{ item.firmName }}</div>
            <div>{{ item.paramValue }}</div>
            <div class="r_title2-score">
              <div>综合评分</div>

              
              <span class="red">{{ item.apparatusScore }}</span>
              <span>分</span>
              
            </div>
          </div>
        </div>
      </div>

      <div class="more">
        <button v-if="isMore" @click="more">加载更多</button>
      </div>
    </div>
    <div class="topinfo__r">
      <div class="line"></div>
      <div class="topinfo__r_contanier">
        <div class="topinfo__r_header">
          <span>热门榜单</span>
        </div>
        <div class="topinfo__r_content">
          <div v-for="(item, index) in hotTopList" :key="index">
            <div
              class="content-img top1"
              @click="to2('/top/info/', item)"
              v-if="index == 0"
            >
              <div class="box">
                <img :src="item.rankIcon" alt="" />
                <div class="content-title top1">
                  {{ item.rankName }}
                </div>
              </div>
            </div>
            <div
              class="content-img"
              v-if="index != 0"
              @click="to2('/top/info/', item)"
            >
              <div class="box2">
                <img :src="item.rankIcon" alt="" />
                <div class="content-title2">
                  {{ item.rankName }}
                </div>
                <div class="content-time">
                  <div class="time">
                    <i class="iconfont icon-time"></i> {{ item.createTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="topinfo__r_content">
          <div class="content-box"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../api/api";
import top from "../api/top";
export default {
  data() {
    return {
      list: [],
      info: {},
      hotTopList: [],
      isMore: true,
      pages: 1,
    };
  },
  created() {
    this.infoRank();
    this.indexTopRank();
  },
  watch: {
    pages(val) {
      this.infoRank(true);
    },
  },
  methods: {
    to(path, item) {
      this.$router.push(`${path}${item.apparatusId}/${item.catId}`);
    },
    to2(path, item) {
      this.$router.push(`${path}${item.rankId}`);
      this.infoRank();
      this.indexTopRank();
    },
    infoRank(flag) {
      let data = {
        rankId: this.$route.params.id,
        page: this.pages,
      };
      top.infoRank(data).then((res) => {
        res.data.data.info.createTime =
          res.data.data.info.createTime.split(" ")[0];
        this.info = res.data.data.info;
        let newList = res.data.data.data;
        newList.forEach((item) => {
          if (!parseFloat(item.apparatusScore)) {
            item.apparatusScore = 0;
          } else {
            item.apparatusScore = parseFloat(item.apparatusScore).toFixed(1);
          }
        });
        if (newList.length == 0) {
          this.isMore = false;
        }
        this.list = flag ? [...this.list, ...newList] : newList;
        // this.topList = isMore? [...this.topList, ...newList] : newList;
      });
    },
    indexTopRank() {
      api.indexTopRank().then((res) => {
        let data = res.data.data.data;
        data.forEach((item) => {
          item.createTime = item.createTime.split(" ")[0];
        });
        this.hotTopList = data;
        // console.log(this.hotTopList);
      });
    },
    more() {
      this.pages++;
    },
  },
};
</script>
<style lang="scss" scoped>
.topinfo {
  justify-content: space-between;
  display: flex;
  .topinfo__l {
    width: 859px;
    // padding: 21px;
    background: #fff;

    .topinfo__l_header {
      padding: 21px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 5px;

      .title {
        font-size: 27px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #2d3f56;
      }
      .time {
        font-size: 19px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
      }
    }
    .line {
      width: 48px;
      height: 8px;
      background: #0092ff;
      margin-left: 21px;
    }
    .topinfo__l_box {
      margin: 30px 12px 0 0;
      display: flex;
      padding: 35px 21px;
      border-radius: 7px;
      &:hover {
        box-shadow: 0px 5px 23px 1px rgba(0, 0, 0, 0.10999999940395355);
      }
      // justify-content: space-between;
      &.top1 {
        //
      }
      .topinfo__l_box-l {
        border-radius: 7px;
        // border: 1px solid #333;
        width: 267px;
        // height: 181px;
        position: relative;
        img {
          width: 100%;
          border-radius: 7px;
        }
        .topIcon {
          width: 60px;
          height: 38px;
          position: absolute;
          left: -14px;
          top: -19px;
          i {
            font-size: 40px;
            &.t1 {
              color: #ff3355;
            }
            &.t2 {
              color: #ff3355;
            }
            &.t3 {
              color: #8784ff;
            }
          }
        }
        .topIndex {
          width: 35px;
          height: 29px;
          background: rgba(255, 255, 255, 0.4399999976158142);
          border-radius: 7px 0px 7px 0px;
          font-size: 19px;
          font-family: Epilogue-ExtraBold Italic, Epilogue;
          font-weight: normal;
          color: #ffffff;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
        }
      }
      .topinfo__l_box-r {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        .r_title1 {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #2d3f56;
          padding-top: 8px;
        }
        .r_title2 {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: auto auto;

          grid-row-gap: 15px;
          div {
            &:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 3;
            }
            &:nth-child(3) {
              
            }
          }
          .r_title2-score {
            display: flex;
            align-items: center;
            .red {
              font-size: 24px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ec6969;
              margin-left: 17px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .more {
      text-align: center;
      margin-top: 69px;
      margin-bottom: 48px;
      button {
        background: #fff;
        width: 213px;
        height: 64px;
        box-shadow: 0px 5px 24px 1px
          rgba(2.9999998211860657, 147.9999303817749, 255, 0.1099999904632568);
        border-radius: 65px 65px 65px 65px;
        opacity: 1;
        border: 1px solid #0394ff;
        font-size: 21px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0394ff;
      }
    }
  }
  .topinfo__r {
    width: 328px;
    border-radius: 7px;
    // border-top: 5px solid #0394FF;

    .topinfo__r_contanier {
      border: 1px solid #e9e9e9;
      padding: 0 19px;
    }
    .line {
      height: 5px;
      background: #0394ff;
      border-radius: 7px 7px 0 0;
    }
    .topinfo__r_header {
      padding: 10px 0px;
      span {
        padding: 5px 0;
        border-bottom: 3px solid #0092ff;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0394ff;
      }
    }
    .topinfo__r_content {
      .content-img {
        padding: 13px 0px;
        border-bottom: 1px solid #d2d4d6;
        &.top1 {
          padding: 25px 0px;
        }
        .box {
          position: relative;
          img {
            width: 100%;
            border-radius: 7px 7px 0 0;
          }
        }
        .box2 {
          display: grid;
          grid-template-columns: 125px 50%;
          grid-template-rows: auto auto;
          grid-column-gap: 15px;
          grid-row-gap: 15px;
          img {
            width: 100%;

            border-radius: 7px;
            grid-row-start: 1;
            grid-row-end: 3;
          }
          .content-title2 {
            font-size: 17px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .content-time {
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            font-family: Helvetica-Regular, Helvetica;
            font-weight: 400;
            color: #b1b1b1;
            .time {
              display: flex;
            }
            i {
              margin-right: 11px;
            }
          }
        }
      }

      .content-title {
        &.top1 {
          // width: 217px;
          position: absolute;
          padding: 13px 21px;
          bottom: 13px;
          left: 50%;
          transform: translateX(-50%);
          border: 1px solid #ffffff;
          font-size: 19px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
</style>